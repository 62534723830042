.loanDetail {
    padding-left: 10px;
    vertical-align: top;
    line-height: 45px;
    font-size: 15px;
    float: left;

    .label {
        font-size: 25px;
    }
}

.loanDescription {
    padding-left: 20px;
    vertical-align: top;
    line-height: 45px;
    font-size: 15px;
}