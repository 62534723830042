.field-customization {
    min-height: 32px;
}

.dx-lookup.field-customization .dx-lookup-field {
    padding: 0 5px;
}

.title-field > div {
    display: inline-block;
    vertical-align: middle;
    line-height: 30px;
    font-size: 20px;
    margin-left: 5px;
}

.inactive {
    color: gray;
}

.custom-field > img {
    vertical-align: middle;
    height: 30px;
    width: 30px;
}

.custom-field > div {
    display: inline-block;
    vertical-align: middle;
    line-height: 30px;
    font-size: 14px;
    margin-left: 5px;
}

.custom-item > img {
    height: 48px;
    width: 48px;
    margin-right: 10px;
}

.custom-item > div {
    display: inline-block;
    vertical-align: top;
    line-height: 48px;
    font-size: 15px;
}

.dx-viewport:not(.dx-theme-ios7) .dx-fieldset {
    width: 40%;
    float: left;
}

.dx-field > .dx-lookup {
    flex: 1;
}