#gridContainer {
  
}

.custom-field > img {
  vertical-align: middle;
  height: 25px;
  width: 25px;
}

.custom-field > div {
  margin-left: 10;
}

.dx-row img {
  height: 30px;
}

#gridContainer tr.main-row td:not(:first-child) {
  height: 21px;
}

#gridContainer tr.notes-row {
  white-space: normal;
  vertical-align: top;
}

#gridContainer tr.notes-row td {
  height: 70px;
  color: #999;
}

.dark #gridContainer tr.notes-row td {
  color: #777;
}

#gridContainer tbody.employee:hover {
  background-color: #EBEBEB;
}

.dark #gridContainer tbody.employee:hover {
  background-color: #484848;
}

#gridContainer tbody.employee:hover tr.main-row td {
  color: #000;
}

.dark #gridContainer tbody.employee:hover tr.main-row td {
  color: #CCC;
}

#gridContainer tbody.employee:hover tr.notes-row td {
  color: #888;
}