.dx-field-label {
    font-size: 18px;
    color: black;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
}

.dx-field-value {
    float: left;
}

.dx-fieldset {
    width: 100%;
}