.left {
    float: left;
}

.center {
    .buyForm {
        position: relative;
        float: left;
        padding-left: 15px;
    }

    .sellForm {
        position: relative;
        float: left;
        padding-left: 15px;
    }
}

.right {
    float: right;
}

.tradeDetail {
    position: absolute;
    padding-left: 10px;
    vertical-align: top;
    line-height: 45px;
    font-size: 15px;
    float: left;

    .label {
        font-size: 25px;
    }
}