#speaker-detail {
    /*
     * Speaker Background
     */
  
    ion-toolbar {
      position: absolute;
  
      top: 0;
      left: 0;
      right: 0;
  
      --background: transparent;
      --color: white;
    }
  
    ion-toolbar ion-back-button,
    ion-toolbar ion-button,
    ion-toolbar ion-menu-button {
      --color: white;
    }
  
    .speaker-background {
      position: relative;
  
      display: flex;
  
      padding-top: var(--ion-safe-area-top);
  
      align-items: center;
      justify-content: center;
  
      flex-direction: column;
  
      height: calc(250px + var(--ion-safe-area-top));
    }
  
    .speaker-background img {
      width: 120px;
      border-radius: 90%;
      margin-top: calc(-1 * var(--ion-safe-area-top));
    }
  
    .speaker-background h2 {
      position: absolute;
  
      bottom: 10px;
  
      color: black;
    }
  
    .md .speaker-background {
      box-shadow: rgba(0, 0, 0, 0.2) 0 3px 1px -2px, rgba(0, 0, 0, 0.14) 0 2px 2px 0px, rgba(0, 0, 0, 0.12) 0 1px 5px 0;
    }
  
    .ios .speaker-background {
      box-shadow: rgba(0, 0, 0, 0.12) 0 4px 16px;
    }
  
    /*
     * Speaker Details
     */
  
    .speaker-detail p {
      margin-left: 6px;
      margin-right: 6px;
    }
  
    .speaker-detail hr {
      margin-top: 20px;
      margin-bottom: 20px;
  
      background: var(--ion-color-step-150, #d7d8da);
    }
  
  }
  